@import '~antd/dist/antd.variable.less';
html,body,.App{
  min-height: 100vh !important;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fontWeightBold{
  font-weight: 600;
}

.verticalWrapper{
  width: 100vw;
  height: 100vh;
}
.verticalCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
  max-width: 2000px;
  padding: 32px;
}
.text-right{
  text-align: right;
}
.caption{
  font-size: 14px;
}
.logoImg{
  max-width: 60px;
  padding: 8px;
  &_sm{
    max-width: 45px;
  }
}
.changeChannelBtn{
  color: #fff !important;
  span{
    text-decoration: underline !important;
  }
}
.btnFontSize{
  font-size: 14px;
}
//antd
.ant-btn-primary {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
.ant-menu-title-content{
  margin-left: 16px !important;
}
.ant-tag{
  padding: 2px 12px !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  border-radius: 5px !important;
  letter-spacing: 4px !important;
  opacity: 0.9 !important;
  border: none !important;
  font-weight: 600;
  &.actOn{
    background-color: @green-1;
    color: #07c58c;
  }
  &.actOff{
    // background-color: #999;
    color:#999;
  }
}
.ant-form-item-explain-connected{
  font-size: 11px !important;
  letter-spacing: .5px !important;
}