.logotest {
  height: 32px;
  margin: 16px;
  background: url('/public/fakeLogo.png') no-repeat;
  background-size: contain;
}

/* .site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
} */
/* .sidebar{
  position: fixed !important;
  top: 0;
  height: 100%;
} */
