[data-theme='compact'] .ant-advanced-search-form,
.ant-advanced-search-form {
  padding: 24px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

@colorlist: #a2aae2,#b4b8c5,#a5a299,#8d818c,#1d70a2;
@length: length(@colorlist);
@random : `Math.ceil(Math.random()*(@{length}))`;
@randomColor: extract(@colorlist, @random);
.cusCard{
  width: 240px;
  // min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px !important;
  cursor: pointer;
  transition: all .4s;
  background-color: #fcfcfc !important;
  .wrap{
    display: flex;
    align-items: center
  }
  .circle{
    width: 24px;
    height: 24px;
    border-radius: 50%;

  }
  .circle-wrap{
    height: 36px;
    display: flex;
    align-items: center;
  }
  .generate-circle(50);

  .generate-circle(@n,@i:0) when (@i =< @n){
    // @newI: (@i % 5);
    .circle@{i}{
      background-color: extract(@colorlist, `(@{i}) % 5 + 1`) ;
    }
    .generate-circle(@n, (@i + 1));
  }

  
  .tenantImg{
    max-width: 100px;
    max-height: 100px;
    border-radius: 20px;
    overflow: hidden;
    img{
      object-fit: cover;
    }
  }
  &:hover{
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 30px -10px;
  }
}
.noTenant{
  box-shadow: none;
  cursor: default;
  &:hover{
    box-shadow: none;
  }
}
.noTenantConfirm .ant-modal-body .ant-modal-confirm-btns button:first-child{
  display: none;
}
