@import '~antd/dist/antd.variable.less';
.ticket{
    // border: 1px solid #d9d9d9;
    // height: 100px;
    position: relative;
    background-color: @green-1;
    border: 1px solid @green-4;
    margin-bottom: 16px;
    padding: 16px 32px;
    color: fade(@black, 50%);
    border-radius: 12px;
    box-sizing: border-box;
    &::after{
      content: '';
      position: absolute;
      height: 20px;
      width: 10px;
      // border-radius: 10px;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      border: 1px solid @green-4;
      border-left: 0;
      // border: 1px solid #d9d9d9;
      background-color: #fff;
      top: 50%;
      left: 0;
      transform: translate(-1px,-50%);
    }
    &::before{
      content: '';
      position: absolute;
      height: 20px;
      width: 10px;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
      border: 1px solid @green-4;
      border-right: 0;
      background-color: #fff;
      top: 50%;
      right: 0;
      transform: translate(1px,-50%);
    }
    i{
      color: fade(@black, 50%);
    }
    p{
      margin-bottom: 0;
    }
    &_not_act{
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      &::after{
        border: 1px solid #ddd;
        border-left: 0;
      }
      &::before{
        border: 1px solid #ddd;
        border-right: 0;
      }
    }
  }
.ticketAdder{
  width: 100%;
  height: 100%;
  min-width: 358px;
  // min-height: 123px;
  min-height: 110px;
  border: 1px solid @green-4;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .addIcon{
    font-size: 22px;
    color: @green-4;
  }
  &:hover{
    background-color: @green-1;
  }
  &[disabled]{
    border: 1px solid @disabled-color;
    .addIcon{
      color: @disabled-color;
    }
    &:hover{
      background-color: @component-background;
    }
  }
}
// .previewWrapper{
//   .ant-upload-list-picture-card-container{
//     width: 200px;
//   }
// }
