.loginSocial{
    flex-direction: column;
    background-color: #222;
    .titleFont1{
        font-size: 24px;
        color: #fff !important;
        font-weight: 500 !important;
    }
    .titleFont2{
        font-size: 36px;
        color: #fff !important;
    }
    .loginTitle{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 350px;
        width: 100%;
        margin-bottom: 30px;
        
        img{
            width: 44px;
            height: 44px;
            margin-bottom: 8px;
            margin-right: 16px;
        }
        .sub{
            font-weight: 400 !important;
            position: relative;
            top: -4px;
            font-size: 16px;
        }
    }
    .wrapper{
        min-width: 350px;
        padding: 32px 64px;
        border-radius: 10px;
        box-shadow: 0px 0px 30px rgba(#000,0.4);
        background: #333;

        .submitBtn{
            margin-top: 16px;
        }
        .errMsg.ant-alert-with-description{
            align-items: center;
        }
        .hint{
            font-size: 12px;
            text-align: center;
            margin-top: 16px;
            span{
                color: #fff9;
            }
        }
    }
}
